import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog26/image1.jpg"
import image2 from "../../images/blog26/image2.png"
import image3 from "../../images/blog26/image3.png"
import image4 from "../../images/blog26/image4.png"
import image5 from "../../images/blog26/image5.png"
import image6 from "../../images/blog26/image6.png"


export default function MostMeniscusTearsDontNeedToBeFixed(){
    return(
        <BlogPostOld
            src={image2}
            title={"Most Meniscus Tears Don’t Need to be Fixed"}
            summary={"As a society we are often sold quick fixes and overpromised results when it comes to recurrent pain. If you are watching a sporting event on TV start paying attention at how many commercials that pop up on the screen from copper fit to medications that depict the transformative effect these products have on the pain and disability that you are experiencing."}
            date={"Oct. 17, 2022"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}
        >
            <>
                <p>
                    As a society we are often sold quick fixes and overpromised results when it
                    comes to recurrent pain. If you are watching a sporting event on TV start
                    paying attention at how many commercials that pop up on the screen from
                    copper fit to medications that depict the transformative effect these
                    products have on the pain and disability that you are experiencing.
                </p>
                <p>
                    The middle aged man playing catch with his son reaching down to rub his
                    painful knee, visually distraught and concerned. Cue in the knee sleeve and
                    his discomfort is gone and he is back to smiling and all fun and games with
                    the kiddo. But what about those who don’t have this success? How are they
                    supposed to feel? What are they to do?
                </p>
                <p>
                    Every time you have to bend down to one knee you notice a deep pain in your
                    knee. You have begun to notice some more noises in your knee and any time
                    you try to run the pain elevates. None of the tricks or medications as sold
                    on TV or remedies given to by friends and family is working.
                </p>
                <p>
                    It is logical that the next step should be to consult with a doctor to get
                    some help and guidance from an expert. This leads to receiving an x-ray that
                    shows nothing and the knee pain is now forcing you to avoid some of the
                    activities that may blow off some stress or spend time with loved ones.
                </p>
                <p>
                    With growing concerns, you want more answers. You seek out a specialist who
                    then orders an MRI and FINALLY there is something to be seen! Your physician
                    explains that you have a tear in your meniscus and explains the best course
                    of action is a round of physical therapy. If this physical therapy is
                    “successful” after 6-8 weeks than a surgery would be necessary.
                </p>
                <p>
                    You knew there was something “wrong” in there and if nothing else you at
                    least don’t feel crazy. This new hope and positivity slowly starts to fizzle
                    though, as you realize that your pain is still there despite taking out the
                    running and all other “high impact” activities. Now not only are you in
                    pain, but you are able to do LESS than before.&nbsp;
                </p>
                <p>
                    Stress has increased because you haven’t been able to be as physically
                    active. You find you are more lethargic throughout the day, yet can’t sleep
                    as well at night. 5 weeks in, your concern has returned in full force
                    thinking the only option that is left is surgery.&nbsp;
                </p>
                <p>
                    At this point, let’s put a pause on this story; one of which we hear far too
                    often. First let’s talk about what the meniscus is and what are some key
                    points that many doctors are unfortunately not up-to-date on.&nbsp;
                </p>
                <p>
    <h2 className={"text-center"} >
      <strong>What is the Meniscus?</strong>
    </h2>
                </p>
                <p>
                    The meniscus is a lining of cartilage - a smooth, thick, rubbery tissue that
                    rests between the shin bone and thigh bone. The medial or inside portion is
                    shaped like a “C” and the outside portion is more of a “O” or oval
                    shape.&nbsp;
                </p>
                <img src={image5} alt={"knee diagram"}/>
                <p>
                    The meniscus, like many other tissues in the body, changes in appearance as
                    we age. We used to think these changes were an indication of an injury which
                    needed fixing. However, we know now that this relationship is not so clear
                    (more on that later).
                </p>
                <p>What does a Meniscus Tear Look Like?</p>
                <p>
                    There are a wide variety of different meniscus tears that are really
                    classified by what they look like and the direction of the tear. Here are
                    some of the different classifications that you may hear a physician tell you
                    or read on an imaging report given to you.
                </p>
                <img src={image3} alt={"tear patterns"}/>
                <p>
                    Looking at these it may seem a bit overwhelming and may be cause for a
                    concern because after all if something is torn, it should be fixed right?
                    Well, not exactly…
                </p>
                <p>
    <h2  className={"text-center"} >
      <strong>Not All Meniscus Tears Hurt</strong>
    </h2>
                </p>
                <p>
                    There are some people that walk around that have never experienced knee
                    pain, yet have meniscus tears. For example,{" "}
                    <a
                        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7237395/"
                        target="_blank"
                        rel="noopener"
                    >
                        this study looked at 230 people with ZERO pain
                    </a>{" "}
                    and found that 30 percent of them walked around with some sort of meniscus
                    tear yet experience no discomfort, had no issues carrying out their daily
                    life and lived happy and worry free (in regards to their knees at
                    least).&nbsp;
                </p>
                <p>
    <h2  className={"text-center"} >
      <strong>Meniscus Tears Don’t Increase Risk of Pain</strong>
    </h2>
                </p>
                <p>
                    So people can have meniscus tears and experience no pain whatsoever. Okay
                    fine, but if you do have a tear you must be more likely to have pain right?
                    Well, no not necessarily.&nbsp;
                </p>
                <p>
                    <a
                        href="https://www.bmj.com/content/345/bmj.e5339"
                        target="_blank"
                        rel="noopener"
                    >
                        Guermazi, et al showed in 2012
                    </a>
                    that when you compare those with knee pain and without knee pain, the odds
                    of anatomical changes (including meniscus tears) are almost identical. This
                    is really telling and shows us that anatomical changes that we call tears,
                    degeneration, bone spurs, etc is probably not the only driver of knee pain,
                    frustration and disability.&nbsp;
                </p>
                <img src={image4} alt={"knee mri chart"}/>
                <p>
    <h2  className={"text-center"} >
      <strong>Meniscus Tear Does NOT Dictate Rehab</strong>
    </h2>
                </p>
                <p>
                    So now that we know that meniscus tears are more normal than we once thought
                    before, it is important to understand that having an image of your knee is
                    not the biggest driver on what is best for your rehab. Think about it this
                    way, if you are an active individual that is young and has a very robust
                    training experience, should you have the same rehab as someone who is much
                    older, more stressed and doesn’t have any training background?
                </p>
                <p>
                    If you go to a place where you feel like you are receiving the same
                    treatment as everyone else in the room, moving through the same stations and
                    getting the same sheet of paper - you didn’t fail PT, PT fail you!
                </p>
                <p>
                    This picture is a phenomenal visual presentation of how even though two
                    people have the same “diagnosis” they are two VERY different people. Your
                    rehab should treat YOU as a person not your body part.&nbsp;
                </p>
                <img src={image} alt={"venn diagram"}/>
                <p>
    <h2  className={"text-center"} >
      <strong>
        Surgeries are Not More Successful Than Non-surgical Options
      </strong>
    </h2>
                </p>
                <p>
                    Surgeries do make sense in some cases and can be helpful for some but I
                    think they are often oversold. If the meniscus fragment is causing an
                    obstruction in the joint line (most common being a bucket handle tear) and
                    you notice that your knee is locking or giving out, surgery may be a good
                    route and I would recommend seeking an orthopedic consultation.&nbsp;
                </p>
                <p>
                    However for more simple meniscus tears that do not involve the meniscus
                    causing joint obstruction (which are far more common) the numbers will tell
                    us that surgeries aren’t as successful as you’d assume.&nbsp;
                </p>
                <img src={image6} alt={"surgery vs physiotherapy"}/>
                <p>
                    Another important point from this is that knee pain does take time,
                    especially if there wasn’t one fall or traumatic event that kickstarted the
                    experience.&nbsp;
                </p>
                <p>
                    Now let’s go back to where we were in the story and think about how this
                    could be better understood and managed. If we know meniscus tears are
                    relatively normal in people without any pain and meniscus tears do not
                    increase risk of having pain while also knowing that surgery doesn’t
                    outperform non-surgery - this can be very encouraging for one to resume
                    activities that you love to do.&nbsp;
                </p>
                <p>
                    If the practitioner that is working with you treats you like an individual
                    and really understands what you cannot currently do and what you would like
                    to do in the future - you can find a starting place that you feel safe with.
                    Over time you can build the capacity to do a little bit more and a little
                    bit more with each small with giving a little bit more hope.
                </p>
                <p>
                    This over time allows you to FEEL how strong and capable your body can be
                    doing the things that used to be a cause for concern. This is how I believe
                    one should be helped and this is why we started Kinetic Impact. So we can
                    treat everyone we see like family and teach them how to lift their physical
                    limitations so they can train for the game!
                </p>
                <p>
                    <a href="https://kineticimpact.janeapp.com/" target="_blank" rel="noopener">
                        Book your appointment here
                    </a>
                    if you are interested in working together and move without limitations.
                </p>
                <p>Sources:</p>
                <p className={"w-full"}>
                    <a
                        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7237395/"
                        target="_blank"
                        rel="noopener"
                    >
                        Horga LM, Hirschmann AC, Henckel J, Fotiadou A, Di Laura A, Torlasco C,
                        D'Silva A, Sharma S, Moon JC, Hart AJ. Prevalence of abnormal findings in
                        230 knees of asymptomatic adults using 3.0 T MRI. Skeletal Radiol. 2020
                        Jul;49(7):1099-1107. doi: 10.1007/s00256-020-03394-z. Epub 2020 Feb 14.
                        PMID: 32060622; PMCID: PMC7237395.
                    </a>
                </p>
                <div className={"w-full"}>
                <p>
                    <a
                        href="https://www.bmj.com/content/345/bmj.e5339"
                        target="_blank"
                        rel="noopener"
                    >
                        Guermazi, A., et al. “Prevalence of Abnormalities in Knees Detected by MRI
                        in Adults without Knee Osteoarthritis: Population Based Observational
                        Study (Framingham Osteoarthritis Study).” BMJ, vol. 345, no. aug29 1, 29
                        Aug. 2012, pp. e5339–e5339, www.ncbi.nlm.nih.gov/
                        pmc/articles/PMC3430365/,
                        10.1136/bmj.e5339.
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.bmj.com/content/354/bmj.i3740"
                        target="_blank"
                        rel="noopener"
                    >
                        Kise, Nina Jullum, et al. “Exercise Therapy versus Arthroscopic Partial
                        Meniscectomy for Degenerative Meniscal Tear in Middle Aged Patients:
                        Randomised Controlled Trial with Two Year Follow-Up.” BMJ, 20 July 2016,
                        p. i3740, 10.1136/bmj.i3740.
                    </a>
                </p>
                </div>
            </>

        </BlogPostOld>

    )
}